import { useStores } from '../stores/store';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { config } from "../config";
import HashLoader from 'react-spinners/HashLoader';

const emailValidation = email => {
	return (
		email.indexOf('@') !== -1 &&
		email.trim().length >= 3 &&
		email.substring(email.indexOf('@'), email.length).indexOf('.') !== -1
	);
};

function LoginPage(props) {
	const { auth } = useStores();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	// eslint-disable-next-line
	let navigate = useNavigate();

	useEffect(() => {
		auth.resetLocalInfo();
	}, [])

	const submit = async evt => {
		evt.preventDefault();

		setIsLoading(true);

		if (!emailValidation(email)) {
			setError("E-mail non valida");
			setIsLoading(false);
			return;
		} else if (password === '') {
			setError("Password non valida");
			setIsLoading(false);
			return;
		}

		const res = await auth.login(email, password);
		if (!res || res.error || !res.success) {
			setIsLoading(false);
			setError(res.msg);
		} else {
			navigate('/media-sync');
		}
	};

	const onChangeEmail = evt => {
		setEmail(evt.target.value);
	};
	
	const onChangePassword = evt => {
		setPassword(evt.target.value);
	};
	
	const onRegister = evt => {
		navigate(`/signup`);
	};

	const onPasswordRecovery = evt => {
		navigate(`/password-recovery`);
	};

	return (
		<div className={`grid w-screen h-screen place-items-center bg-contain bg-no-repeat ${config.isMiri() ? 'logo-miri' : 'logo-pinguin'}`}>
			<div className="w-screen h-screen flex flex-col items-center justify-center">
				<form method='post' onSubmit={submit} className="flex flex-col items-center">
					<div className="border-2 border-primary rounded-lg p-2 shadow-2xl">
						<input type="email" value={email} onChange={onChangeEmail} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" placeholder="Email" />
						<div className="form-group">
							<input type="password" value={password} onChange={onChangePassword} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" placeholder="Password" />
						</div>
					</div>
					{ error.length > 0 &&
						(
							<span className="mt-2 text-primary">{error}</span>
						)
					}
					{ isLoading &&
						(
							<HashLoader className="mt-2" color="#EF7d00" />
						)
					}
					<button type="submit" className="bg-primary text-white text-center rounded-lg p-2 mt-4">
						ACCEDI
					</button>
				</form>
				<button onClick={onRegister} className="border-2 border-primary text-primary text-center rounded-lg p-2 mt-2">
					REGISTRATI
				</button>
				<button onClick={onPasswordRecovery} className="text-primary text-center rounded-lg p-2 mt-2">
					Password dimenticata?
				</button>
				<div className="bg-primary w-full flex flex-col items-center sticky top-[100vh] p-2">
					<span>CampuStore Srl Società benefit - sede legale in Via Villaggio Europa 3, 36061 Bassano del Grappa (VI). REA VI 229294, P.IVA 02409740244, Capitale Sociale € 50.000 i.v. © 2023</span>
					<div className='flex flex-row space-x-2'>
						<a href="https://go.campustore.it/miri-legal">Area legale</a>
						<span>|</span>
						<a href="https://go.campustore.it/miri-privacy">Privacy</a>
						<span>|</span>
						<a href="https://go.campustore.it/miri-cookies">Cookies</a>
						<span>|</span>
						<a href="https://go.campustore.it/miri-sitemap">Sitemap</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LoginPage;
