import { useStores } from '../stores/store';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

function SearchSchoolPage(props) {
	const { auth } = useStores();
	const [error, setError] = useState<string>("");
	const [results, setResults] = useState<any[]>([]);
	const { register, handleSubmit, formState: { errors } } = useForm();

	const onSubmit = async data => {
		const res = await auth.searchSchool(data['key']);
		setResults(res.data);
	};

	return (
		<div className="grid h-screen place-items-center">
			<div className="w-full flex flex-col items-center justify-center">
				<img alt="" src="userIcon.png" />
				<form className="border-2 border-primary p-2 rounded-2xl flex flex-col items-center justify-center" onSubmit={handleSubmit(onSubmit)}>
					<label>Chiave di ricerca:</label>
					<input {...register("key", { required: true })} placeholder="Minimo 4 caratteri" className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
					{errors.keyRequired && <span>Questo campo è obbligatorio</span>}

					{ error && error.length > 0 &&
						(
							<span className="text-primary">{error}</span>
						)
					}

					<button type="submit" className="bg-primary text-white text-center rounded-lg p-2 mt-4">
						Cerca
					</button>
				</form>
				<button onClick={props.onClose} className="border-2 border-primary text-primary text-center rounded-lg p-2 mt-2">
					Indietro
				</button>
				{ results.length > 0 && <table className='w-full mt-4'>
					<thead>
						<th>Nome</th>
						<th>Codice</th>
						<th>Provincia</th>
						<th>CAP</th>
						<th>Regione</th>
						<th>Città</th>
					</thead>
                    <tbody>
                        { results.map((e, index) => (
                            <tr key={index} className='p-2 border border-primary'>
                                <td className='p-2'>{e.name}</td>

								<td className='p-2'>{e.code}</td>
								<td className='p-2'>{e.province}</td>
								<td className='p-2 text-center'>{e.zip_code}</td>
								<td className='p-2'>{e.region}</td>
								<td className='p-2'>{e.city}</td>
								<td className='p-2'>
									<button onClick={() => props.onSubmit(e)} className="bg-primary text-white text-center rounded-lg p-2">
										Seleziona
									</button>
								</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
				}
			</div>
		</div>
	)
}

export default SearchSchoolPage;