import React from 'react';
import { config } from './config';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';

//Style
import 'tailwindcss/tailwind.css';
import './index.css';

//Stores
import { Stores, StoresContext } from './stores/contexts';

//Pages
import LoginPage from './pages/loginPage';
import {Helmet} from "react-helmet";

//Routing
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';

import { AuthStore } from './stores/netStore';
import { Container } from './pages/common/container';
import { Header } from './pages/common/header';

import LessonSelectionPage from './pages/user/lessonSelectionPage';
import LessonGroupSelectionPage from './pages/user/lessonGroupSelectionPage';
import EducationLevelSelectionPage from './pages/user/educationLevelSelectionPage';
import InteractiveLessonPage from './pages/user/lessons/interactiveLessonPage';
import SignupPage from './pages/signupPage';
import AdminCodesPage from './pages/admin/codes/codesPage';
import AdminLessonGroupsPage from './pages/admin/lessons/lessonGroupsPage';
import AdminNewLessonGroupPage from './pages/admin/lessons/newGroup/newGroupPage';
import AdminNewLessonComposerPage from './pages/admin/lessons/newLesson/newLessonComposerPage';
import AdminLessonsPage from './pages/admin/lessons/lessonsPage';
import AdminSchoolsPage from './pages/admin/schools/schoolsPage';
import AdminUsersPage from './pages/admin/users/usersPage';
import AdminUserEditPage from './pages/admin/users/userEditPage';
import MediaSyncPage from './pages/mediaSyncPage';
import AdminCodeEditPage from './pages/admin/codes/codeEditPage';
import StartupPage from './pages/startupPage';
import PasswordRecovery1Page from './pages/passwordRecovery1Page';
import PasswordRecovery2Page from './pages/passwordRecovery2Page';
import AdminCountriesPage from './pages/admin/countries/countriesPage';
import AdminCountryEditPage from './pages/admin/countries/countryEditPage';
import AdminEducationLevelsPage from './pages/admin/education-levels/educationLevelsPage';
import AdminEducationLevelEditPage from './pages/admin/education-levels/educationLevelEditPage';
import AdminCodeGenerationPage from './pages/admin/codes/codeGenerationPage';
import { CountrySelectionPage } from './pages/user/countrySelectionPage';
import AdminAssociateLessonGroupPage from './pages/admin/lessons/associate/associatePage';

const authStore = new AuthStore();
let stores: Stores = {
	auth: authStore,
};

if (config.env === 'production') {
	console.log = function no_console() { };
}

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: true,
		},
	},
});

export const ContainerRoute = observer(({ children, ...rest }: any) => {
	return <Route {...rest} render={({ location }) =>

			children
  } />;
  });

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<React.StrictMode>
		<StoresContext.Provider value={stores}>
			<Helmet>
				<title>{config.isMiri() ? "Aula MIRI" : "Tavolo Pinguin"}</title>
				<link rel="icon" href={config.isMiri() ? "favicon_miri.ico" : "favicon_pinguin.ico"} />
			</Helmet>
			<QueryClientProvider client={queryClient}>
				<Router>
					<Header />

					<Routes>
						{/* Public routes */}
						<Route path='/startup/' element={<StartupPage />} />
						<Route path='/login/' element={<LoginPage />} />
						<Route path='/signup/' element={<SignupPage />} />
						<Route path='/password-recovery/' element={<PasswordRecovery1Page />} />
						<Route path='/password-reset/' element={<PasswordRecovery2Page />} />

						<Route path='/media-sync/' element={<MediaSyncPage />} />

						{/* User routes */}
						<Route path='/user/country-selection/' element={<CountrySelectionPage />} />
						<Route path='/user/education-level-selection/' element={<EducationLevelSelectionPage />} />
						<Route path='/user/lesson-group-selection/' element={<LessonGroupSelectionPage />} />
						<Route path='/user/lesson-selection/' element={<LessonSelectionPage />} />

						<Route path='/user/interactive-lesson/' element={<InteractiveLessonPage />} />

						{/* Admin routes */}
						<Route path='/admin/dashboard/' element={<Navigate to='/admin/dashboard/schools' />} />
						<Route path='/admin/dashboard/schools/' element={<Container><AdminSchoolsPage /></Container>} />
						<Route path='/admin/dashboard/users/' element={<Container><AdminUsersPage /></Container>} />
						<Route path='/admin/dashboard/users/:id' element={<Container><AdminUserEditPage /></Container>} />
						<Route path='/admin/dashboard/countries/' element={<Container><AdminCountriesPage /></Container>} />
						<Route path='/admin/dashboard/countries/:id' element={<Container><AdminCountryEditPage /></Container>} />
						<Route path='/admin/dashboard/education-levels' element={<Container><AdminEducationLevelsPage /></Container>} />
						<Route path='/admin/dashboard/education-levels/:id' element={<Container><AdminEducationLevelEditPage /></Container>} />

						<Route path='/admin/dashboard/lessons' element={<Container><AdminLessonsPage /></Container>} />
						<Route path='/admin/dashboard/lessons/:id' element={<AdminNewLessonComposerPage />} />

						<Route path='/admin/dashboard/lesson-groups' element={<Container><AdminLessonGroupsPage /></Container>} />
						<Route path='/admin/dashboard/lesson-groups/:id' element={<Container><AdminNewLessonGroupPage /></Container>} />
						<Route path='/admin/dashboard/lesson-groups/associate/:id' element={<Container><AdminAssociateLessonGroupPage /></Container>} />

						<Route path='/admin/dashboard/codes/' element={<Container><AdminCodesPage /></Container>} />
						<Route path='/admin/dashboard/codes/:id' element={<Container><AdminCodeEditPage /></Container>} />
						<Route path='/admin/dashboard/codes/generate' element={<Container><AdminCodeGenerationPage /></Container>} />

						{/* Fallback route */}
						<Route path='*' element={<Navigate to='/login' />} />
					</Routes>
				</Router>
			</QueryClientProvider>
		</StoresContext.Provider>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
