import { useEffect, useState } from 'react';
import { useStores } from '../../stores/store';
import { AdminHeader } from './adminHeader';
import { SchoolHeader } from './schoolHeader';
import { observer } from 'mobx-react-lite';

export const Header = observer((props: any) => {
    const { auth } = useStores();
    const [userAccessLevel, setUserAccessLevel] = useState(null);

    useEffect(() => {
        setUserAccessLevel(auth.profile?.type || null);
    }, [auth.profile])

    if (userAccessLevel == 1)
        return <AdminHeader />

    if (userAccessLevel == 1)
        return <SchoolHeader />

    return <></>;
});
