import React, { useEffect, useRef, useState } from 'react'
import { useStores } from '../../../stores/store'
import { Link } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import HashLoader from 'react-spinners/HashLoader';
import { Table } from '../../common/table';

function AdminCountriesPage() {
  const { auth } = useStores();

  const [isDataError, setIsDataError] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [countries, setCountries] = useState(null);

  useEffect(() => {
    setIsDataLoading(true);
    setIsDataError(false);

    auth.getCountries().then((res) => {
      setCountries(res.data);
      setIsDataLoading(false);
    }).catch((error) => {
      setIsDataLoading(false);
      setIsDataError(true);
    });
  }, [])

  const columns = React.useMemo(
      () => [
      {
          Header: "Scuole",
          columns: [
          {
            Header: "Modifica",
            accessor: "id",
            Cell: ({ cell }) => (
              <Link to={
                  `/admin/dashboard/countries/${cell.row.values.id}`}>
                <FaEdit />
              </Link>
            )
          },
          {
              Header: 'Nome',
              accessor: 'name',
          },
          {
            Header: 'Priorità',
            accessor: 'priority',
          },
        ],
      },
      ],
      []
  );

  if (isDataLoading)
    return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

  if (isDataError)
    return <div className='grid h-screen place-items-center'>Impossibile caricare la pagina</div>

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-row justify-end">
        <span className="text-4xl">Nazioni</span>
      </div>
      <div className='flex flex-row justify-end mt-4 space-x-2'>
        <Link className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" to="/admin/dashboard/countries/new">Aggiungi</Link>
      </div>
      <Table columns={columns} data={countries} />
    </div>
  )
}

export default AdminCountriesPage
