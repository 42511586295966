import React, { useEffect, useRef, useState } from 'react'
import { useStores } from '../../../stores/store'
import { CSVLink } from 'react-csv';
import HashLoader from 'react-spinners/HashLoader';
import { Table } from '../../common/table';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Popup from 'reactjs-popup';

function AdminSchoolsPage() {
  let navigate = useNavigate();

  const { auth } = useStores();
  const searchKey = useRef<string>("");
  const searchRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [referralId, setReferralId] = useState(null);

  const [currentCountry, setCurrentCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  const [isFilterError, setIsFilterError] = useState(false);
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [isDataError, setIsDataError] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    setReferralId(searchParams.get('referral_id'));

    setIsFilterLoading(true);
    setIsFilterError(false);

    auth.getCountries().then((res) => {
      setCountries(res.data);
      if (res.data.length > 0) {
        setCurrentCountry(res.data[0]);
      }
      setIsFilterLoading(false);
    }).catch((error) => {
      setIsFilterLoading(false);
      setIsFilterError(true);
    });
  }, [])

  useEffect(() => {
    getSchools();
  }, [currentCountry])

  const getSchools = () => {
    if (currentCountry == null)
      return;

    setIsDataLoading(true);
    setIsDataError(false);

    auth.getSchools(searchKey.current, currentCountry.id).then((res) => {
      setSchools(res.data);
      setIsDataLoading(false);
    }).catch((error) => {
      setIsDataLoading(false);
      setIsDataError(true);
    });
  }
    
  const columns = React.useMemo(
      () => [
      {
          Header: "Scuole",
          columns: [
          {
              Header: 'Nome',
              accessor: 'name',
          },
          {
            Header: 'Codice meccanografico',
            accessor: 'code',
          },
          {
            Header: "Tipo",
            accessor: "license",
            Cell: props => {
              return props.value == 1 ? "Pinguin" : "Miri"
            }
          },
          {
            Header: "Utenti",
            accessor: "users",
            Cell: props => {
              return props.value.map((elm) => elm + " ");
            }
          },
          {
            Header: "Newsletter?",
            accessor: "newsletter_consent",
            Cell: props => {
              return props.newsletter_consent ? 'Sì' : 'No'
            }
          },
        ],
      },
      ],
      []
  );

  const selectedColumns = React.useMemo(
    () => [
    {
        Header: "Seleziona",
        accessor: "id",
        Cell: props => {
          return <button onClick={() => {setSelectedSchool(props.row.values.id); setShowConfirm(true);}} className='border-2 border-primary rounded-full p-2'>Seleziona</button>
        }
      },
      {
        Header: "Scuole",
        columns: [
        {
            Header: 'Nome',
            accessor: 'name',
        },
        {
          Header: 'Codice meccanografico',
          accessor: 'code',
        },
      ],
    },
    ], []
  );

  const search = event => {
    if (event.target.value && event.target.value.length < 4)
      return;

    searchKey.current = event.target.value;
    getSchools();
  }

  const save = () => {
    auth.associateActivationCode(selectedSchool, referralId);
    navigate(`/admin/dashboard/codes/`);
  }

  if (isFilterLoading)
    return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

  if (isFilterError)
    return <div className='grid h-screen place-items-center'>Impossibile caricare la pagina</div>

  return (
    <div className="flex flex-col gap">
      <Popup open={showConfirm}
          position="right center"
          closeOnDocumentClick
      >
          <div className="flex flex-col items-center gap-6 p-6">
              <span>Vuoi associare la licenza a questa scuola?</span>
              <div className="flex flex-row items-center gap-6">
                  <button onClick={save} className="bg-primary text-white font-bold py-2 px-4 rounded-full">Si</button>
                  <button onClick={(e) => { e.preventDefault(); setShowConfirm(false) }} className="border-2 border-primary text-primary font-bold py-2 px-4 rounded-full">No</button>
              </div>
          </div>
      </Popup>

      <div className="flex flex-row justify-end">
        <span className="text-4xl">Scuole</span>
      </div>
      <div className='flex flex-row mt-4 justify-between'>
        <div className='flex flex-row space-x-2'>
        <input onChange={search} ref={searchRef} placeholder="Cerca (min 4 caratteri)" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
        <CSVLink className="bg-primary hover:bg-blue-700 text-white font-bold p-4 rounded-full" data={schools}>Esporta</CSVLink>
        </div>
      </div>
      {isDataLoading && <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div> }

      {isDataError && <div className='grid h-screen place-items-center'>Impossibile caricare i dati</div> }

      {referralId == null && !isDataError && !isDataLoading && <Table columns={columns} data={schools} /> }

      {referralId != null && !isDataError && !isDataLoading && <Table columns={selectedColumns} data={schools} /> }
    </div>
  )
}

export default AdminSchoolsPage
