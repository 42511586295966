function isProd() {
    return process.env.NODE_ENV === 'production';
}

export const config = {
    env: isProd() ? "production" : "development",
    apiEndpoint: "https://backend.aulamiri.it",
    apiBaseUri: '',
    mediaEndpoint: "https://backend.aulamiri.it/media/",

    isMiri() {
        return window.location.href.indexOf('aulamiri.it') != -1;
    },

    isPinguin() {
        return window.location.href.indexOf('tavolopinguin.it') != -1;
    },

    getEndpointUri() {
        return `${config.apiEndpoint}${config.apiBaseUri}`
    }
}
