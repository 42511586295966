import { useStores } from '../stores/store';
import { useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import SearchSchoolPage from './searchSchoolPage';

function SignupPage(props) {
	const { auth } = useStores();
	const [error, setError] = useState<string>("");
	const [typeSelected, setTypeSelected] = useState<number>(0);
	const [showSearchSchool, setShowSearchSchool] = useState<boolean>(false);
	const currentSchool = useRef<any>(null);
	const { register: registerSchool, handleSubmit: handleSubmitSchool, setValue: setValueSchool, formState: formStateSchool } = useForm();
	const { register: registerIndividual, handleSubmit: handleSubmitIndividual, setValue: setValueIndividual, formState: formStateIndividual } = useForm();
	// eslint-disable-next-line
	const [searchParams, setSearchParams] = useSearchParams();
	let navigate = useNavigate();

	const onSubmitSchool = async data => {
		setError("");

		if (data.password !== data.confirmPassword) {
			setError("Le password non coincidono");
			return;
		}

		if (data.password.length < 8) {
			setError("La password deve essere lunga almeno 8 caratteri");
			return;
		}

		let res;

		if (currentSchool.current !== null) {
			res = await auth.signup(data.email, data.password, data.code,
				data.activationCode, currentSchool.current.name, currentSchool.current.city, currentSchool.current.province,
				currentSchool.current.region, data.telephone_number, data.zip_code);
		} else {
			res = await auth.signup(data.email, data.password, data.code,
				data.activationCode, "", "", "", "", data.telephone_number, "");
		}

		if (res == null) {
			setError(`Errore generale, contattare l'assistenza clienti.`);
		} else if (!res || res.error) {
			setError(`Registrazione fallita: ${res.msg}`);
		} else {
			if (res.success)
				navigate('/');
			else
				setError(`Registrazione fallita: ${res.msg}`)
		}
	};

	const onSubmitIndividual = async data => {
		setError("");

		if (data.password !== data.confirmPassword) {
			setError("Le password non coincidono");
			return;
		}

		if (data.password.length < 8) {
			setError("La password deve essere lunga almeno 8 caratteri");
			return;
		}

		const res = await auth.signup(data.email, data.password,
			null, data.activationCode, data.name,
			data.city, data.province, data.region, data.telephone_number, data.zip_code);

		if (res == null) {
			setError(`Errore generale, contattare l'assistenza clienti.`);
		} else if (!res || res.error) {
			setError(`Registrazione fallita: ${res.msg}`);
		} else {
			if (res.success)
				navigate('/');
			else
				setError(`Registrazione fallita: ${res.msg}`)
		}
	};


	const back = evt => {
		navigate(`/?key=${searchParams.get('key')}`);
	};

	const onSearchedSchool = school => {
		setShowSearchSchool(false);
		currentSchool.current = school;
		setValueSchool('code', school.code, { shouldValidate: true })
	}

	if (typeSelected == 0)
		return <div className="grid h-screen place-items-center">
			<div className='flex flex-col items-center'>
				<label className="block text-gray-700 text-sm font-bold mb-2">Tipo</label>
				<select onChange={(e) => setTypeSelected(+e.target.value)} className="bg-primary text-white rounded-full p-2 cursor-pointer">
					<option value="0">Seleziona</option>
					<option value="1">Scuola</option>
					<option value="2">Altro</option>
				</select>
			</div>
		</div>

	
	if (typeSelected == 1)
		return (
			<div className="grid h-screen place-items-center">
				{showSearchSchool && <SearchSchoolPage onSubmit={onSearchedSchool} onClose={() => setShowSearchSchool(false) }/> }

				{!showSearchSchool && <div className="w-full flex flex-col items-center justify-center">
					<img alt="" src="userIcon.png" />
					<form className="border-2 border-primary p-2 rounded-2xl flex flex-col items-center justify-center" onSubmit={handleSubmitSchool(onSubmitSchool)}>
						<label>Codice Seriale *:</label>
						<input {...registerSchool("activationCode", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
						{formStateSchool.errors.activationCode && <span className='text-red'>Questo campo è obbligatorio</span>}

						<label className="mt-4">Codice meccanografico scuola *:</label>
						<input {...registerSchool("code", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
						{formStateSchool.errors.code && <span className='text-red'>Questo campo è obbligatorio</span>}
						<button onClick={() => setShowSearchSchool(true)} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary">Cerca</button>

						<label className="mt-4">Telefono:</label>
						<input {...registerSchool("telephone_number")} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />

						<label className="mt-4">Email *:</label>
						<input {...registerSchool("email", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
						{formStateSchool.errors.email && <span className='text-red'>Questo campo è obbligatorio</span>}
						
						<label className="mt-4">Password *:</label>
						<input type="password" {...registerSchool("password", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
						{formStateSchool.errors.password && <span className='text-red'>Questo campo è obbligatorio</span>}

						<label className="mt-4">Conferma password *:</label>
						<input type="password" {...registerSchool("confirmPassword", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
						{formStateSchool.errors.confirmPassword && <span className='text-red'>Questo campo è obbligatorio</span>}

						<div className='flex flex-col items-center mt-4'>
							<span className='text-xs'>I dati personali saranno trattati da CampuStore srl come da informativa resa</span>
							<span className='text-xs'>ai sensi dell’art 13 del Regolamento (UE) 2016/679. Proseguendo con la registrazione dichiaro:</span>
							<span className='text-xs'>di aver letto e di accettare l’informativa della privacy.</span>
						</div>

						<div className='flex flex-row items-end space-x-1'>
							<input type="checkbox" {...registerSchool("privacy_consent", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
							{formStateSchool.errors.privacy_consent && <div className='text-red'>Questo campo è obbligatorio</div>}
						</div>

						<div className='flex flex-col items-center mt-4'>
							<span className='text-xs'>Desidero essere aggiornato (anche attraverso le newsletters) sui prodotti i servizi</span>
							<span className='text-xs'>e le iniziative di Campustore srl. e dei suoi partners e contribuire con la mia opinione contribuire</span>
							<span className='text-xs'>a migliorare i prodotti e servizi acconsentendo quindi esplicitamente al trattamento dei dati personali</span>
							<span className='text-xs'>da me comunicati per le finalità indicate nell’informativa che dichiaro di aver letto e compreso</span>
							<span className="text-xs">e in cui si spiega più nel dettaglio il tipo di trattamento.</span>
							<input type="checkbox" {...registerSchool("newsletter_consent")} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
						</div>

						<button type="submit" className="bg-primary text-white text-center rounded-lg p-2 mt-4">
							REGISTRATI
						</button>
					</form>

					{ error && error.length > 0 &&
							(
								<span className="text-primary mt-4">{error}</span>
							)
						}
					<button onClick={back} className="border-2 border-primary text-primary text-center rounded-lg p-2 mt-2">
						ANNULLA
					</button>
				</div>
				}
			</div>
		)

	if (typeSelected == 2)
		return (
			<div className="grid h-screen place-items-center">
				<img alt="" src="userIcon.png" />
				<form className="border-2 border-primary p-2 rounded-2xl flex flex-col items-center justify-center" onSubmit={handleSubmitIndividual(onSubmitIndividual)}>
					<div className='flex flex-row space-x-4'>
						<div className='flex flex-col'>
							<label className="mt-4">Codice di attivazione *:</label>
							<input {...registerIndividual("activationCode", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
							{formStateIndividual.errors.activationCode && <span className='text-red'>Questo campo è obbligatorio</span>}
						</div>

						<div className='flex flex-col'>
							<label className="mt-4">Ragione sociale *:</label>
							<input {...registerIndividual("name", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
							{formStateIndividual.errors.name && <span className='text-red'>Questo campo è obbligatorio</span>}
						</div>
					</div>

					<div className='flex flex-row space-x-4'>
						<div className='flex flex-col'>
							<label className="mt-4">Città *:</label>
							<input {...registerIndividual("city", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
							{formStateIndividual.errors.city && <span className='text-red'>Questo campo è obbligatorio</span>}
						</div>

						<div className='flex flex-col'>
							<label className="mt-4">Provincia *:</label>
							<input {...registerIndividual("province", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
							{formStateIndividual.errors.province && <span className='text-red'>Questo campo è obbligatorio</span>}
						</div>
					</div>

					<div className='flex flex-row space-x-4'>
						<div className='flex flex-col'>
							<label className="mt-4">Indirizzo *:</label>
							<input {...registerIndividual("address", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
							{formStateIndividual.errors.address && <span className='text-red'>Questo campo è obbligatorio</span>}
						</div>

						<div className='flex flex-col'>
							<label className="mt-4">CAP *:</label>
							<input {...registerIndividual("zip_code", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
							{formStateIndividual.errors.zip_code && <span className='text-red'>Questo campo è obbligatorio</span>}
						</div>
					</div>

					<div className='flex flex-row space-x-4'>
						<div className='flex flex-col'>
							<label className="mt-4">Regione *:</label>
							<input {...registerIndividual("region", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
							{formStateIndividual.errors.region && <span className='text-red'>Questo campo è obbligatorio</span>}
						</div>

						<div className='flex flex-col'>
							<label className="mt-4">Telefono:</label>
							<input {...registerIndividual("telephone_number")} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
						</div>
					</div>

					<div className='flex flex-col'>
						<label className="mt-4">Email *:</label>
						<input {...registerIndividual("email", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
						{formStateIndividual.errors.email && <span className='text-red'>Questo campo è obbligatorio</span>}
					</div>

					<div className='flex flex-col'>
						<label className="mt-4">Password *:</label>
						<input type="password" {...registerIndividual("password", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
						{formStateIndividual.errors.password && <span className='text-red'>Questo campo è obbligatorio</span>}
					</div>

					<div className='flex flex-col'>
						<label className="mt-4">Conferma password *:</label>
						<input type="password" {...registerIndividual("confirmPassword", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
						{formStateIndividual.errors.confirmPassword && <span className='text-red'>Questo campo è obbligatorio</span>}
					</div>

					{ error && error.length > 0 &&
						(
							<span className="text-primary">{error}</span>
						)
					}

					<button type="submit" className="bg-primary text-white text-center rounded-lg p-2 mt-4">
						REGISTRATI
					</button>
					<button onClick={back} className="border-2 border-primary text-primary text-center rounded-lg p-2 mt-2">
						ANNULLA
					</button>
				</form>

			</div>
		)
}

export default SignupPage;