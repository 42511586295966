import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useStores } from "../../../../stores/store";
import HashLoader from "react-spinners/HashLoader";

export default function AdminAssociateLessonGroupPage() {
    const { auth } = useStores();
    const params = useParams();
    const [isDataError, setIsDataError] = useState(false);
    const [lessonGroup, setLessonGroup] = useState<any>(null);
    const [availableLessons, setAvailableLessons] = useState<any[]>(null);
    const [associatedLessons, setAssociatedLessons] = useState<any[]>([]);
    let navigate = useNavigate();

    useEffect(() => {
        getLessonGroupData();
        getLessons();
    }, []);

    const getLessonGroupData = () => {
        auth.getLessonGroup(params.id).then(res => {
            setLessonGroup(res.data);
            setAssociatedLessons(res.data.lessons)
        }).catch((error) => {
            setIsDataError(true);
          });    }

    const getLessons = () => {
        auth.getLessons().then(res => {
            setAvailableLessons(res.data);
        }).catch((error) => {
            setIsDataError(true);
          });    
    }

    const isLessonAssociated = (id) => {
        for (let i = 0; i < associatedLessons?.length; i++) {
            let lesson = associatedLessons[i];

            if (lesson.id == id) {
                return true;
            }
        }

        return false;
    }

    const changeLessonAssociation = (lesson) => {
        if (isLessonAssociated(lesson.id)) {
            setAssociatedLessons(associatedLessons.filter(obj => obj.id !== lesson.id));
        } else {
            setAssociatedLessons([...associatedLessons, lesson]);
        }
    }

    const postData = async () => {
        await auth.associateLessonGroup(params.id, associatedLessons);

        navigate(`/admin/dashboard/lesson-groups/`);
    }

    if (lessonGroup == null)
        return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

    if (isDataError)
        return <div className='grid h-screen place-items-center'>Impossibile caricare la pagina</div>

    return (
        <div className="flex flex-row gap-6 justify-start items-start">
            <div className="flex flex-col items-stretch gap-6 w-full">
                <div className="w-full bg-white flex flex-row justify-end">
                    <button className="bg-primary text-white p-2 rounded-full text-xl" onClick={postData}>Associa</button>
                </div>

                <div className="w-full bg-white flex flex-col gap-4 p-8 rounded-3xl shadow-xl">
                    <table className="min-w-full divide-y divide-primary bg-white">
                        <thead>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Associato</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Titolo</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nome interno</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Formato</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Descrizione</th>
                        </thead>
                        { availableLessons?.map((lesson) => (
                            <tr>
                                <td className='px-6 py-4 whitespace-nowrap'>
                                    <input type="checkbox" defaultChecked={isLessonAssociated(lesson.id)} onChange={() => changeLessonAssociation(lesson)} />
                                </td>
                                <td className='px-6 py-4 whitespace-nowrap'>
                                    <span className="rounded-3xl p-2 text-black m-1">{lesson.title}</span>
                                </td>
                                <td className='px-6 py-4 whitespace-nowrap'>
                                    <span className="rounded-3xl p-2 text-black m-1">{lesson.name}</span>
                                </td>
                                <td className='px-6 py-4 whitespace-nowrap'>
                                    <span className="rounded-3xl p-2 text-black m-1">{lesson.screen_ratio}</span>
                                </td>
                                <td className='px-6 py-4 whitespace-nowrap'>
                                    <span className="rounded-3xl p-2 text-black m-1">{lesson.description}</span>
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>
            </div>
        </div>
    );
}