import React, { useEffect, useRef, useState } from 'react'
import { useForm } from "react-hook-form";
import { useStores } from '../../../stores/store'
import {CSVLink } from 'react-csv';
import HashLoader from 'react-spinners/HashLoader';

import Moment from 'moment';
import Popup from 'reactjs-popup'
import { Link } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import { Table } from '../../common/table';

function AdminCodesPage() {
  const { auth } = useStores();

  const [isFilterError, setIsFilterError] = useState(false);
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [isDataError, setIsDataError] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [codes, setCodes] = useState([]);

  const [resetCodeId, setResetCodeId] = useState<number>(0);
  const filterValue = useRef<string>("free");
  const searchKey = useRef<string>("");
  const searchRef = useRef(null);

  useEffect(() => {
    getCodes();
  }, [])

  const getCodes = () => {
    setIsDataLoading(true);
    setIsDataError(false);

    auth.getCodes(filterValue.current, searchKey.current).then((res) => {
      setCodes(res.data);
      setIsDataLoading(false);
    }).catch((error) => {
      setIsDataLoading(false);
      setIsDataError(true);
    });

  }

  const parseJson = (data) => {
    let countries = "";
    let educationLevels = "";
    let lessonGroups = "";

    if (data == null)
      return ""
  
    let jsonData = JSON.parse(data)

    jsonData['countries'].forEach(element => {

      if (element != null && 'name' in element)
        countries += `${element.name} `;
    });

    jsonData['educationLevels'].forEach(element => {
      if (element != null && 'description' in element)
        educationLevels += `${element.description} `;
      else if (element != null && 'name' in element)
        educationLevels += `${element.name} `;
    });

    jsonData['lessonGroups'].forEach(element => {
      if (element != null && 'name' in element)
        lessonGroups += `${element.name} `;
    });

    return `${countries} - ${educationLevels} - ${lessonGroups}`;
  }

  const columns = React.useMemo(
      () => [
      {
          Header: "Codici",
          columns: [
            {
              Header: "Modifica",
              accessor: "id",
              Cell: ({ cell }) => (
                <Link to={
                    `/admin/dashboard/codes/${cell.row.values.id}`}>
                  <FaEdit />
                </Link>
              )
            },
            {
                Header: 'Codice',
                accessor: 'code',
            },
            {
              Header: "Data di generazione",
              accessor: "generation_date",
              Cell: ({ cell }) => (
                Moment(cell.row.values.generation_date).format('DD/MM/yyyy')
              )
            },
            {
              Header: "Data di attivazione",
              accessor: "activation_date",
              Cell: ({ cell }) => (
                cell.row.values.activation_date ? Moment(cell.row.values.activation_date).format('DD/MM/yyyy') : "Non attivato"
              )
            },
            {
              Header: "Validità",
              accessor: "validity_year",
              Cell: props => {
                return props.value == 1000 ? "Lifetime" : props.value + " anno/i"
              }
            },
            {
              Header: "Tipo",
              accessor: "type",
              Cell: props => {
                return props.value == 1 ? "Pinguin" : "Miri"
              }
            },
            {
              Header: "Nome scuola",
              accessor: "school_name",
              Cell: props => {
                if (props.value == null || props.value.length == 0) {
                  if (props.row.values.activation_date != null) {
                    return "";
                  }
                  return <Link className="border-2 border-primary rounded-full p-2" to={`/admin/dashboard/schools/?referral_id=${props.row.values.id}`}>
                    Assegna
                  </Link>
                } else {
                  return props.value
                }
              }
            },
            {
              Header: "Codice meccanografico",
              accessor: "school_code",
            },
            {
              Header: "Dettagli",
              accessor: "license_data",
              Cell: props => {
                return parseJson(props.value)
              }
            },
            {
              Header: "Note",
              accessor: "notes",
            },
            {
              Header: "Lotto",
              accessor: "production_lot",
            },
            {
              Header: "Commerciale",
              accessor: "commercial_agent",
            },
            {
              Header: "Reset",
              Cell: props => (
                props.row.values.activation_date ? <button className='bg-primary rounded-3xl p-3' onClick={() => setResetCodeId(props.row.values.id)}>Reset</button> : <span></span>
              )
            },
          ],
      },
    ],
      []
  );

  const handleFilterChange = e => {
    filterValue.current = e.target.value;
    getCodes();
  }

  const search = event => {
    if (event.target.value && event.target.value.length < 4)
      return;

    searchKey.current = event.target.value;
    getCodes();
  }

  const clear = () => {
    searchKey.current = null;
    searchRef.current.value = "";
    getCodes();
  }

  const handleSubmitReset = async () => {
    await auth.resetCode(resetCodeId);
    getCodes();
  }

  if (isFilterLoading)
    return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

  if (isFilterError)
    return <div className='grid h-screen place-items-center'>Impossibile caricare la pagina</div>

  return (
    <div className="w-full">
      <Popup open={resetCodeId != 0}
        position="right center"
        closeOnDocumentClick>

        <form onSubmit={handleSubmitReset} className="w-full flex flex-col items-center gap-6 m-4">
          Vuoi veramente resettare questo codice di attivazione?

          <div className="w-full flex flex-row justify-center gap-6">
            <button className="bg-primary text-white font-bold py-2 px-4 rounded-full" type="submit">Sì</button>
            <button onClick={(e) => { e.preventDefault(); setResetCodeId(0) }} className="border-2 border-primary text-primary font-bold py-2 px-4 rounded-full">No</button>
          </div>
        </form>
      </Popup>

      <div className="flex flex-col">
        <div className="flex flex-row justify-end">
          <span className="text-4xl">Codici di attivazione</span>
        </div>
        <div className='flex flex-row mt-4 justify-between'>
          <div className='flex flex-row mt-4 space-x-2'>
            <select className="bg-primary text-white rounded-full p-2 cursor-pointer" onChange={handleFilterChange}>
              <option value="free">Liberi</option>
              <option value="used">Usati</option>
            </select>
            <input onChange={search} ref={searchRef} placeholder="Cerca (min 4 caratteri)" className="shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            <button onClick={clear} className="bg-primary hover:bg-blue-700 text-white font-bold p-4 rounded-full mr-4">Cancella</button>
            <CSVLink className="bg-primary hover:bg-blue-700 text-white font-bold p-4 rounded-full mr-4" data={codes}>Esporta</CSVLink>
          </div>
          <Link to={'/admin/dashboard/codes/generate'} className='bg-primary flex flex-row items-center p-4 rounded-full font-bold text-white'>Genera codici</Link>
        </div>
        {isDataLoading && <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div> }

        {isDataError && <div className='grid h-screen place-items-center'>Impossibile caricare i dati</div> }

        {!isDataError && !isDataLoading && <Table columns={columns} data={codes} /> }
      </div>
    </div>
  )
}

export default AdminCodesPage
