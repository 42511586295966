import React, { useEffect, useRef, useState } from 'react'
import { useStores } from '../../../stores/store'
import { Link } from 'react-router-dom';
import HashLoader from 'react-spinners/HashLoader';
import { CSVLink } from 'react-csv';
import { FaEdit } from 'react-icons/fa';
import { getElementById } from '../../common/functions';
import { Table } from '../../common/table';

function AdminUsersPage() {
  const { auth } = useStores();
  const searchKey = useRef<string>("");
  const searchRef = useRef(null);

  const [currentCountry, setCurrentCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  const [isFilterError, setIsFilterError] = useState(false);
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [isDataError, setIsDataError] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    searchKey.current = localStorage.getItem('users_searchKey');
    
    setIsFilterLoading(true);
    setIsFilterError(false);

    auth.getCountries().then((res) => {
      setCountries(res.data);
      if (res.data.length > 0) {
        setCurrentCountry(res.data[0]);
      }
      setIsFilterLoading(false);
    }).catch((error) => {
      setIsFilterLoading(false);
      setIsFilterError(true);
    });
  }, [])

  useEffect(() => {
    getUsers();
  }, [currentCountry])

  const getUsers = () => {
    if (currentCountry == null)
      return;

    setIsDataLoading(true);
    setIsDataError(false);

    auth.getUsers(searchKey.current).then((res) => {
      setUsers(res.data);
      setIsDataLoading(false);
    }).catch((error) => {
      setIsDataLoading(false);
      setIsDataError(true);
    });
  }
    
  const columns = React.useMemo(
      () => [
      {
          Header: "Utenti",
          columns: [
            {
              Header: "Modifica",
              accessor: "id",
              Cell: ({ cell }) => (
                <Link to={
                    `/admin/dashboard/users/${cell.row.values.id}`}>
                  <FaEdit />
                </Link>
              )
            },
            {
                Header: 'Nome/Ragione sociale',
                accessor: 'surname',
            },
            {
              Header: 'Tipo',
              accessor: 'type',
              Cell: ({ cell }) => (
                cell.row.values.type == 1 ? <span>Admin</span> : 
                cell.row.values.type == 2 ? <span>Professore</span> : 
                cell.row.values.type == 3 ? <span>Scuola</span> : 
                cell.row.values.type == 4 ? <span>Demo</span> : <span></span>
              )
            },
            {
                Header: 'Scuola',
                accessor: 'fk_school_name',
            },
            {
              Header: 'Meccanografico',
              accessor: 'fk_school_code',
            },
            {
              Header: 'Tipo',
              accessor: 'fk_school_license',
              Cell: ({ cell }) => (
                cell.row.values.fk_school_license == 1 ? <span>Pinguin</span> : <span>Miri</span> 
              )
            },
          ],
      },
      {
        Header: "Login",
        columns: [
        {
            Header: 'E-mail',
            accessor: 'email',
        },
        {
          Header: "Attivo",
          accessor: "is_old",
          Cell: ({ cell }) => (
            cell.row.values.is_old ? "No" : "Si"
          )
        },
        ],
    },
    ],
      []
  );

  const search = event => {
    if (event.target.value && event.target.value.length < 4)
      return;

    localStorage.setItem('users_searchKey', event.target.value);

    searchKey.current = event.target.value;
    getUsers();
  }

  const handleCountryChange = ({ target }) => {
    let country = getElementById(countries, target.value);
    setCurrentCountry(country);
  }

  if (isFilterLoading)
    return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

  if (isFilterError)
    return <div className='grid h-screen place-items-center'>Impossibile caricare la pagina</div>

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-end">
        <span className="text-4xl">Utenti</span>
      </div>

      <div className="flex flex-row justify-between">
        <div className="flex flex-row space-x-2">
          <select onChange={handleCountryChange} className="bg-primary text-white rounded-full p-4 cursor-pointer">
            {countries?.map(({ id, name }, index) => <option value={id} >{name}</option>)}
          </select>
          <input onChange={search} ref={searchRef} defaultValue={searchKey.current} placeholder="Cerca (min 4 caratteri)" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          <CSVLink className="bg-primary hover:bg-blue-700 text-white font-bold p-4 rounded-full" data={users}>Esporta</CSVLink>
        </div>
        <Link className="bg-primary hover:bg-blue-700 text-white font-bold p-4 rounded-full" to="/admin/dashboard/users/new">Aggiungi</Link>

      </div>

      {isDataLoading && <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div> }

      {isDataError && <div className='grid h-screen place-items-center'>Impossibile caricare i dati</div> }

      {!isDataError && !isDataLoading && <Table columns={columns} data={users} /> }    </div>
  )
}

export default AdminUsersPage
