import { useStores } from '../stores/store';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import HashLoader from 'react-spinners/HashLoader';

function PasswordRecovery1Page(props) {
	const { auth } = useStores();
	const [error, setError] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
	const { register, handleSubmit, formState: { errors } } = useForm();
	let navigate = useNavigate();

	const emailValidation = email => {
		return (
			email.indexOf('@') !== -1 &&
			email.trim().length >= 3 &&
			email.substring(email.indexOf('@'), email.length).indexOf('.') !== -1
		);
	};

	const onSubmit = async data => {
		if (!emailValidation(data.email)) {
			setError("E-mail non valida");
			return;
		}

		setIsLoading(true);

		auth.sendOTP(data.email).then((res) => {
			if (!res || res.error || !res.success) {
				setIsLoading(false);
				setError(res.msg);
			} else {
				setIsEmailSent(true);
			}
		});
	};

	const back = (evt) => {
		navigate(`/login`);
	};

	return (
		<div className="grid h-screen place-items-center">
			{ !isEmailSent ?
				<div className="w-full flex flex-col items-center justify-center">
					<form className="border-2 border-primary p-2 rounded-2xl flex flex-col items-center justify-center" onSubmit={handleSubmit(onSubmit)}>
						<label>E-mail:</label>
						<input {...register("email", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
						{errors.emailRequired && <span>Questo campo è obbligatorio</span>}

						<button type="submit" className="bg-primary text-white text-center rounded-lg p-2 mt-4">
							Invia
						</button>
					</form>

					{ error && error.length > 0 &&
						(
							<span className="text-primary mt-4">{error}</span>
						)
					}
					{ isLoading &&
						(
							<HashLoader className="mt-2" color="#EF7d00" />
						)
					}
					<button onClick={back} className="border-2 border-primary text-primary text-center rounded-lg p-2 mt-2">
						ANNULLA
					</button>
				</div> : <div className='flex flex-col'>
					<span>Ti abbiamo inviato una email con le istruzioni per reimpostare la password.</span>
					<button onClick={back} className="bg-primary text-white text-center rounded-lg p-2 mt-4">
						TORNA
					</button>
				</div>
			}
		</div>
	)
}

export default PasswordRecovery1Page;